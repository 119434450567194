<template>
  <div class="content">
    <div class="content__party content__party--adaptive-col">
      <div class="content__party content__party--start">
        <h1 class="title title--big title--color">Профиль партнера</h1>
        <div
          class="status"
          :class="{'status--new': status.id === 1, 'status--confirm': status.id === 3 }"
          v-for="status in statusOptions"
          :key="status.id"
          v-show="form.status === status.id"
        >
          <span>{{status.name}}</span>
        </div>
      </div>
      <button @click="onCheckProfile" type="button" class="button button--light button--no-indent button--circle">
        <img src="@/assets/img/send-icon.svg" alt="Send icon">
        <span>Отправить на проверку</span>
      </button>
    </div>
    <form class="form" @submit.prevent="checkForm">
      <h2 class="title title--indent">Основная информация</h2>
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Логотип</label>
            <cropper-modal
              @clearPhoto="clearPhoto"
              :getCropCoords="getCropCoords"
              :getCropHash="getCropHash"
              :originalImgUrlProp="photoUrl"
              :croppedImgUrlProp="photoCropUrl"
              :stencilProps="{ aspectRatio: 1 }"
              :square="true"
              ref="cropperModal"
            />
          </div>
          <div class="form-group">
            <label>Категория<sup>*</sup></label>
            <v-select
              label="name"
              :options="categoryOptions"
              :reduce="category => category.id"
              v-model="form.category"
              @change="$v.form.category.$touch()"
              :searchable="false"
              class="select"
              :class="{ 'select--error': $v.form.category.$error }"
              :get-option-label="getLabel"
              placeholder="Выберите категорию"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template slot="clear-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.category.$dirty && !$v.form.category.required">Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>ИНН<sup>*</sup></label>
            <v-select
              @input="setValues"
              v-model="form.org_detail"
              :filterable="false"
              :options="orgDetailOptions"
              @search="onOrgDetailSearch"
              :get-option-label="getLabel"
              placeholder="Введите ИНН"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите ИНН или название организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.inn }}) ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.inn }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label for="name">Название организации<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <input
                id="name"
                type="text"
                class="form-control__input"
                name="name"
                v-model.trim="form.name"
                @change="$v.form.name.$touch()"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="title">Заголовок (RU)<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.title.$error }">
              <input
                id="title"
                type="text"
                class="form-control__input"
                name="title"
                placeholder="Введите заголовок"
                v-model.trim="form.title"
                @change="$v.form.title.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.title.$dirty && !$v.form.title.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="title_en">Заголовок (EN)<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.title_en.$error }">
              <input
                id="title_en"
                type="text"
                class="form-control__input"
                name="title_en"
                placeholder="Введите заголовок"
                v-model.trim="form.title_en"
                @change="$v.form.title_en.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.title_en.$dirty && !$v.form.title_en.enAlpha">
              Принимаются только английские буквы алфавита
            </div>
            <div class="form-group__error" v-if="$v.form.title_en.$dirty && !$v.form.title_en.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="desc">Описание (RU)<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.desc.$error }">
                  <textarea
                    id="desc"
                    name="desc"
                    class="form-control__textarea"
                    v-model.trim="form.desc"
                    placeholder="Введите описание"
                    @change="$v.form.desc.$touch()"
                  >
                  </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.desc.$dirty && !$v.form.desc.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
            <div class="form-group__error" v-if="$v.form.desc.$dirty && !$v.form.desc.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="desc_en">Описание (EN)<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.desc_en.$error }">
                  <textarea
                    id="desc_en"
                    name="desc_en"
                    class="form-control__textarea"
                    v-model.trim="form.desc_en"
                    placeholder="Введите описание"
                    @change="$v.form.desc_en.$touch()"
                  >
                </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.desc_en.$dirty && !$v.form.desc_en.enAlpha">
              Принимаются только английские буквы алфавита
            </div>
            <div class="form-group__error" v-if="$v.form.desc_en.$dirty && !$v.form.desc_en.required">
              Обязательное поле
            </div>
          </div>
        </div>
      </div>
      <h2 class="title title--indent">Контакты</h2>
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <div class="form-control">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="mail@example.com"
                v-model.trim="form.email"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Телефон</label>
            <vue-tel-input
              :value="phoneValue"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !isPhoneValid }"
              @validate="validedPhone"
              disabledFetchingCountry
              validCharactersOnly
              dynamicPlaceholder
              autocomplete="phone_autocomplete_off"
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!isPhoneValid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="site">Сайт</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.site.$error }">
              <input
                id="site"
                type="url"
                name="site"
                class="form-control__input"
                placeholder="https://www.site.com"
                v-model.trim="form.site"
                @change="$v.form.site.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.site.$dirty && !$v.form.site.siteAlpha">
              Введите правильный формат ссылки
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="wa">WhatsApp</label>
            <div class="form-control">
              <input
                id="wa"
                type="tel"
                name="wa"
                class="form-control__input"
                placeholder="79280147272"
                v-model.trim="form.whatsapp"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="viber">Viber</label>
            <div class="form-control">
              <input
                id="viber"
                type="tel"
                name="viber"
                class="form-control__input"
                placeholder="79280147272"
                v-model.trim="form.viber"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="telegram">Telegram</label>
            <div class="form-control">
              <input
                id="telegram"
                type="text"
                name="telegram"
                class="form-control__input"
                placeholder="username"
                v-model.trim="form.telegram"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form__content">
        <div class="form__part">
          <h2 class="title title--indent">Социальные сети</h2>
          <div class="form-group">
            <label for="soc_vk">Вконтакте</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_vk.$error }">
              <input
                id="soc_vk"
                type="url"
                name="soc_vk"
                class="form-control__input"
                placeholder="https://vk.me/id"
                v-model.trim="form.soc_vk"
                @change="$v.form.soc_vk.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_vk.$dirty && !$v.form.soc_vk.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="soc_vk">Одноклассники</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_ok.$error }">
              <input
                id="soc_ok"
                type="url"
                name="soc_ok"
                class="form-control__input"
                placeholder="https://ok.ru/username"
                v-model.trim="form.soc_ok"
                @change="$v.form.soc_ok.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_ok.$dirty && !$v.form.soc_ok.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="soc_inst">Инстаграм</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_inst.$error }">
              <input
                id="soc_inst"
                type="url"
                name="soc_inst"
                class="form-control__input"
                placeholder="https://www.instagram.com/username"
                v-model.trim="form.soc_inst"
                @change="$v.form.soc_inst.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_inst.$dirty && !$v.form.soc_inst.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="soc_fb">Facebook</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_fb.$error }">
              <input
                id="soc_fb"
                type="url"
                name="soc_inst"
                class="form-control__input"
                placeholder="https://www.facebook.com/username"
                v-model.trim="form.soc_fb"
                @change="$v.form.soc_fb.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_fb.$dirty && !$v.form.soc_fb.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="soc_fb">Twitter</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_tw.$error }">
              <input
                id="soc_tw"
                type="url"
                name="soc_inst"
                class="form-control__input"
                placeholder="https://twitter.com/username"
                v-model.trim="form.soc_tw"
                @change="$v.form.soc_tw.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_tw.$dirty && !$v.form.soc_tw.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="soc_fb">Youtube</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.soc_yt.$error }">
              <input
                id="soc_yt"
                type="url"
                name="soc_inst"
                class="form-control__input"
                placeholder="https://www.youtube.com/channel/id"
                v-model.trim="form.soc_yt"
                @change="$v.form.soc_yt.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.soc_yt.$dirty && !$v.form.soc_yt.url">
              Введите правильный формат ссылки
            </div>
          </div>
        </div>
        <div class="form__part">
          <h2 class="title title--indent">Способы оплаты</h2>
          <div class="form-group">
            <div v-for="type in paymentTypes" :key="type.id" class="form__element">
              <label class="checkbox">
                <div class="checkbox__text">{{ type.name }}</div>
                <input type="checkbox" name="type" v-model="form.payment_types" :value="type.id"/>
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
          </div>
          <h2 class="title title--indent">Формат предоставления услуг<sup>*</sup></h2>
          <div class="form-group" :class="{'form-group--error': $v.form.services_types.$error}">
            <div v-for="type in servicesTypes" :key="type.id" class="form__element">
              <label class="checkbox">
                <div class="checkbox__text">{{ type.name }}</div>
                <input type="checkbox" name="type" v-model="form.services_types" :value="type.id"/>
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.services_types.$dirty && !$v.form.services_types.required">
              Выберите значение
            </div>
          </div>
          <h2 class="title title--indent">Фото</h2>
          <div class="form-group">
            <uploader :getFileHash="getFileHash" limit-files="10" :fileUrl="fileUrl" :uploadedFiles="uploadedPhotos"
                      @remove-file="removeFile"/>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit" :disabled="$v.form.$error">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, email, helpers, url} from 'vuelidate/lib/validators';
import {debounce} from 'lodash';

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i);
const enAlpha = helpers.regex('enAlpha', /[a-zA-Z]/i);
const siteAlpha = helpers.regex('siteAlpha', /\bhttp\b|\bhttps\b/i);

import {VueTelInput} from 'vue-tel-input';
import CropperModal from '@/components/Cropper';
import Uploader from '@/components/Uploader';
import SidebarRight from '@/components/SidebarRight';

export default {
  mixins: [validationMixin],
  components: {
    VueTelInput,
    CropperModal,
    Uploader,
  },
  validations: {
    form: {
      email: {email},
      category: {required},
      name: {required},
      title: {required},
      title_en: {enAlpha, required},
      desc: {ruAlpha, required},
      desc_en: {enAlpha, required},
      site: {siteAlpha},
      soc_vk: {url},
      soc_ok: {url},
      soc_inst: {url},
      soc_fb: {url},
      soc_tw: {url},
      soc_yt: {url},
      services_types: {required},
    }
  },
  data() {
    return {
      form: {
        status: null,
        logo: '',
        category: '',
        name: '',
        email: '',
        org_detail: {},
        inn: '',
        title: '',
        title_en: '',
        desc: '',
        desc_en: '',
        kpp: '',
        site: '',
        phone: '',
        whatsapp: '',
        viber: '',
        telegram: '',
        payment_types: [],
        services_types: [],
        soc_vk: '',
        soc_ok: '',
        soc_inst: '',
        soc_fb: '',
        soc_tw: '',
        soc_yt: '',
        photos: [],
        coords: {},
      },
      status: null,
      categoryOptions: [],
      orgDetailOptions: [],
      photoUrl: '',
      photoCropUrl: '',
      isPhoneValid: true,
      phoneValue: '',
      paymentTypes: [],
      servicesTypes: [],
      fileUrl: `${process.env.VUE_APP_PARTNER_URL}files`,
      uploadedPhotos: [],
      showSidebar: false,
      statusOptions: []
    };
  },
  mounted() {
    this.fetchPartner();
    this.$store.dispatch('partner/GET_CATEGORIES')
      .then(response => (this.categoryOptions = response.data.results));
    this.$store.dispatch('partner/GET_STATUS')
      .then(response => (this.statusOptions = response.data.results));
    this.$store.dispatch('partner/GET_PAYMENT')
      .then(response => (this.paymentTypes = response.data.results));
    this.$store.dispatch('partner/GET_SERVICE')
      .then(response => (this.servicesTypes = response.data.results));
  },
  methods: {
    fetchPartner() {
      this.$store.dispatch('partner/profile/GET_DATA')
        .then(response => {
          if (response.status >= 400) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: 'Ошибка получения данных'
            });
          }
          this.form = {...this.form, ...response.data};
          this.uploadedPhotos = [...response.data.photos];
          const ids = [];
          this.form.photos.forEach(photo => {
            if (typeof photo === 'object') ids.push(photo.id);
          });
          this.form.photos = ids;
          this.phoneValue = response.data.phone ?? '';
          this.photoUrl = response.data.logo_url;
          this.photoCropUrl = response.data.crop_url;
          this.status = response.data.status.id;
          this.form.kpp = response.data.org_detail?.kpp;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          });
        });
    },
    checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (this.form.coords.hasOwnProperty('width') && !this.form.logo) {
          this.$refs.cropperModal.sendAdminCrop()
            .then(() => {
              this.sendForm();
            });
        } else {
          this.sendForm();
        }
      }
    },
    sendForm() {
      this.$refs.submit.classList.add('preload');
      delete this.form.crop;
      this.$store.dispatch('partner/profile/PATCH_DATA', this.form)
        .then(response => {
          this.$refs.submit.classList.remove('preload');
          if (response.status >= 400) {
            for (const key in response.data) {
              if (typeof response.data[key] === 'string') {
                this.$notify({
                  type: 'error',
                  text: response.data[key]
                });
              } else {
                this.$notify({
                  type: 'error',
                  text: response.data[key][0]
                });
              }
            }
          } else {
            this.$notify({
              type: 'success',
              title: 'Уведомление',
              text: 'Данные успешно сохранены'
            });
          }
        })
        .catch(error => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: error
          });
        });
    },
    validedPhone(number) {
      if (!number.number.input) return;
      this.isPhoneValid = number.valid;
      if (number.valid) {
        this.form.phone = number.number.e164;
      } else {
        this.form.phone = null;
      }
    },
    clearPhoto() {
      this.form.logo = '';
    },
    getFileHash(value, name) {
      this.form.photos.push(value);
    },
    getCropCoords(value) {
      this.form.coords = value;
    },
    getCropHash(value) {
      this.form.logo = value;
    },
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    setValues() {
      this.form.name = this.form.org_detail?.name;
      this.form.inn = this.form.org_detail?.inn;
      this.form.kpp = this.form.org_detail?.kpp;
    },
    onOrgDetailSearch(search, loading) {
      loading(true);
      this.searchInn(loading, search, this);
    },
    searchInn: debounce((loading, search, vm) => {
      vm.$store.dispatch('partner/GET_INN_OPTIONS', {search}).then(response => {
        vm.orgDetailOptions = response.data;
        loading(false);
      });
    }, 350),
    removeFile(index) {
      this.uploadedPhotos.splice(index, 1);
      this.form.photos.splice(index, 1);
    },
    openSidebar() {
      this.showSidebar = true;
    },
    async onCheckProfile() {
      fetch(`${process.env.VUE_APP_PARTNER_URL}me/profile/check`, {
        method: 'post',
        headers: new Headers({
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }),
      })
        .then(response => response.json())
        .then(result => {
          if (result.array) {
            result.array.forEach(item => {
              this.$notify({
                type: 'error',
                title: 'Ошибка',
                text: item
              });
            });
          } else {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Данные отправлены на проверку'
            });
          }
        });
    }
  }
};
</script>
